import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'Home',
    path: '/',
    icon: icon('ic_home'),
  },
  {
    title: 'Clicker config',
    path: '/cfg-config',
    icon: icon('ic_cfg_config'),
  },
  {
    title: 'Role builder',
    path: '/role-builder',
    icon: icon('ic_role_builder'),
  },
  {
    title: 'Profile',
    path: '/profile',
    icon: icon('ic_profile'),
  },
  {
    title: 'About',
    path: '/about',
    icon: icon('ic_about'),
  },
  {
    title: 'Private policy',
    path: '/private-policy',
    icon: icon('ic_private'),
  },

];

export default navConfig;


/** {
  title: 'LinkedIn Acc',
      path: '/linkedIn-acc',
    icon: icon('ic_linkedIn_acc'),
} */